import { signinRedirect } from '@features/auth';
import { LinearProgress } from '@mui/material';
import { ErrorPage } from '@pages/ErrorPage';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { Outlet } from 'react-router-dom';

const RouteGuard = () => {
    const auth = useAuth();
    const { t } = useTranslation();

    if (auth.isLoading) {
        return <LinearProgress />;
    }

    if (auth.error) {
        return <ErrorPage subtitle={t('errors.page.authSubtitle')} />;
    }

    if (auth.isAuthenticated) {
        return <Outlet />;
    }

    if (!auth.isAuthenticated && auth.activeNavigator !== 'signinRedirect') {
        signinRedirect(auth);
    }

    return <></>;
};

export { RouteGuard };

import { Theme } from '@mui/system';
import { ReactElement } from 'react';

type BannerConfiguration = {
    theme: ColorType;
    text: string;
    icon: ReactElement;
};

type ColorType = 'primary' | 'secondary';

const getColor = (colorType: ColorType, theme: Theme) => {
    switch (colorType) {
        case 'primary':
            return theme.palette.primary.main;
        case 'secondary':
            return theme.palette.secondary.light;
        default:
            return theme.palette.error.main;
    }
};

export { getColor, type BannerConfiguration };

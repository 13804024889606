import { agent } from '@shared/api';

export const deleteDocument = async (conversationId: string, signal?: AbortSignal) => {
    try {
        const deleteResponse = await agent.delete<boolean>(`/api/files?conversationId=${conversationId}`, signal);
        return deleteResponse;
    } catch {
        return false;
    }
};

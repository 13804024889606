import { BrowserRouter as Router } from 'react-router-dom';
import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { customTheme } from '@assets/styles';
import { configureAppInsights } from '@features/logging';
import { ApplicationRoutes } from '@features/router';
import { DocumentsSettings, LinksSettings, ModelServiceSettings } from '@features/settings';
import { ErrorPage } from '@pages/ErrorPage';
import './assets/styles/global.scss';
import './assets/styles/scrollbar.scss';

type Props = {
    appInsightKey: string;
    links: LinksSettings;
    modelService: ModelServiceSettings;
    documentSettings: DocumentsSettings;
};

const App = ({ appInsightKey, links, modelService, documentSettings }: Props) => {
    const { reactPlugin } = configureAppInsights(appInsightKey);

    return (
        <>
            <CssBaseline />
            <AppInsightsErrorBoundary onError={() => <ErrorPage />} appInsights={reactPlugin}>
                <AppInsightsContext.Provider value={reactPlugin}>
                    <ThemeProvider theme={customTheme}>
                        <Router>
                            <ApplicationRoutes
                                links={links}
                                modelService={modelService}
                                documentSettings={documentSettings}
                            />
                        </Router>
                    </ThemeProvider>
                </AppInsightsContext.Provider>
            </AppInsightsErrorBoundary>
        </>
    );
};

export { App };

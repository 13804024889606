import { PropsWithChildren, ReactNode } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
    title: string;
    footer?: ReactNode;
    isOpen: boolean;
    cancelHandler: () => void;
} & PropsWithChildren;

const CustomDialog = ({ title, footer, isOpen, cancelHandler, children }: Props) => {
    return (
        <Dialog open={isOpen} data-testid="customDialog">
            <DialogTitle>{title}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={cancelHandler}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>{children}</DialogContent>
            <DialogActions>{footer}</DialogActions>
        </Dialog>
    );
};

export { CustomDialog };

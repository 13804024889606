import { Button } from '@mui/material';
import { PropsWithChildren } from 'react';
import { EMPTY_GUID } from '../domain';
import { getDownloadBinary } from './api';
import { downloadBlob } from '@assets/utils/download-blob';
import { DocumentBannerConfiguration } from '../documentUpload';

type Props = {
    chatId: string;
    enabled: boolean;
    setManageBanner: (value: DocumentBannerConfiguration) => void;
} & PropsWithChildren;

const DocumentDownload = ({ chatId, enabled, children, setManageBanner }: Props) => {
    const onClickHandler = async () => {
        if (chatId !== EMPTY_GUID && enabled) {
            setManageBanner({ show: true, status: 'startedDownload' });
            const { fileContents, fileDownloadName } = await getDownloadBinary(chatId);
            downloadBlob(fileContents, fileDownloadName);
            setManageBanner({ show: true, status: 'finished' });
        }
    };

    return (
        <Button
            onClick={onClickHandler}
            sx={
                enabled
                    ? {
                          cursor: 'pointer',
                          textTransform: 'unset',
                          p: 0,
                      }
                    : { cursor: 'default', textTransform: 'unset', p: 0 }
            }
            disabled={!enabled}
            fullWidth
        >
            {children}
        </Button>
    );
};

export { DocumentDownload };

import { IconButton, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { NewChatDialog } from '../newChatDialog';
import { componentsMediaQueries } from '@assets/mediaQuery/media-query';

type Props = {
    startNewChat: () => void;
    isDisabled: boolean;
};

const NewChat = ({ startNewChat, isDisabled }: Props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const isMobile = useMediaQuery(componentsMediaQueries.TABLETPORTRAIT);

    return !isDisabled ? (
        <>
            <Tooltip title={t('chat.newChat.label')} placement="right">
                <IconButton
                    onClick={() => setIsDialogOpen(true)}
                    size={isMobile ? 'small' : 'large'}
                    sx={{
                        position: isMobile ? 'relative' : 'absolute',
                        bottom: isMobile ? 0 : 24,
                        left: isMobile ? 0 : 24,
                        bgcolor: isMobile ? 'transparent' : theme.palette.primary.main,
                        color: 'white',
                        '&:hover': { bgcolor: theme.palette.primary.dark },
                        '&:disabled': { bgcolor: grey[300] },
                    }}
                >
                    <AddIcon />
                </IconButton>
            </Tooltip>
            <NewChatDialog isOpen={isDialogOpen} setIsOpen={setIsDialogOpen} acceptAction={startNewChat} />
        </>
    ) : null;
};

export { NewChat };

import { SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ConfirmationButtons, CustomDialog } from '@shared/ui';
import { Button, CircularProgress, Tooltip, Typography } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ChatMessage } from '../domain';
import { createMessage } from './domain';
import { deleteDocument } from './api';
import './documentRemoval.scss';

type Props = {
    conversationId: string;
    setIsDeleted: (value: boolean) => void;
    fileName?: string;
    setMessages: (value: SetStateAction<ChatMessage[]>) => void;
};

const DocumentRemoval = ({ conversationId: id, setIsDeleted, fileName, setMessages }: Props) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const acceptHandler = async (id: string) => {
        const response = await deleteDocument(id);
        setIsDeleted(response);
        const newMessage = createMessage(response);
        setMessages(prevMessages => [...prevMessages, newMessage]);
        setIsDeleting(false);
    };

    return (
        <>
            <Tooltip title={t('documents.banner.deleteButton')} placement="bottom">
                <Button onClick={() => setIsModalOpen(true)} className="delete-button">
                    {isDeleting ? (
                        <CircularProgress size={20} sx={{ color: 'white' }} />
                    ) : (
                        <DeleteOutlineOutlinedIcon />
                    )}
                </Button>
            </Tooltip>
            <CustomDialog
                title={t('documents.banner.deleteButton')}
                isOpen={isModalOpen}
                cancelHandler={() => setIsModalOpen(false)}
                footer={
                    <ConfirmationButtons
                        acceptHandler={() => {
                            setIsModalOpen(false);
                            setIsDeleting(true);
                            acceptHandler(id);
                        }}
                        cancelHandler={() => setIsModalOpen(false)}
                    />
                }
            >
                <Typography variant="body1">
                    <Trans
                        i18nKey={t('documents.banner.deleteDialog', { documentName: fileName })}
                        components={{ b: <b /> }}
                    />
                </Typography>
            </CustomDialog>
        </>
    );
};

export { DocumentRemoval };

import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import { CopyButton, WritingPoints } from '@shared/ui';
import { ChatMessage } from '../domain';
import { MessageAvatar } from '../messageAvatar';
import { MessageElement } from './MessageElement';

type Props = {
    chatMessage: ChatMessage;
    isSendingQuestion: boolean;
};

const MessageContent = ({ chatMessage, isSendingQuestion }: Props) => {
    return (
        <Box
            bgcolor={chatMessage.role === 'user' ? grey[50] : grey[200]}
            width={'100%'}
            padding={2}
            data-testid="message-content"
        >
            <Box maxWidth={1024} margin={'auto'} display={'flex'} justifyContent={'start'} alignItems={'start'} gap={2}>
                <MessageAvatar role={chatMessage.role} />
                {chatMessage.role !== 'loading' ? (
                    <MessageElement role={chatMessage.role} text={chatMessage.text} />
                ) : (
                    <WritingPoints />
                )}

                {chatMessage.role !== 'document' && chatMessage.role !== 'documentError' ? (
                    <CopyButton textToCopy={chatMessage.text} disabled={isSendingQuestion} />
                ) : null}
            </Box>
        </Box>
    );
};

export { MessageContent };

export const downloadBlob = async (blob: Blob | MediaSource, fileName = '') => {
    if (typeof window !== 'undefined' && 'showSaveFilePicker' in window) {
        const options = {
            suggestedName: fileName,
            types: [
                {
                    description: 'Adobe PDF Files',
                    accept: {
                        'application/pdf': ['.pdf'],
                    },
                },
                {
                    description: 'Text Files',
                    accept: {
                        'text/plain': ['.txt'],
                    },
                },
                {
                    description: 'Word Documents',
                    accept: {
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                    },
                },
            ],
        };
        try {
            const fileHandle = await (window as any).showSaveFilePicker(options);
            const writableStream = await fileHandle.createWritable();
            await writableStream.write(blob);
            await writableStream.close();
        } catch (error) {
            console.log(error);
        }
    } else {
        // Fallback para navegadores que no soportan showSaveFilePicker
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    }
};

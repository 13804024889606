import { agent } from '@shared/api';
import { ConversationRequest, ConversationResponse, DocumentUploadResponse } from './domain';

export const uploadDocument = async (file: File, conversationId: string, signal?: AbortSignal) => {
    const data = new FormData();
    data.append('file', file);
    data.append('conversationId', conversationId);

    const response = await agent.postFormData<FormData, DocumentUploadResponse>('/api/files/upload', data, signal);
    return response;
};

export const startChatWithDocument = async (conversationId: string, signal?: AbortSignal) => {
    const conversationResponse = await agent.post<ConversationRequest, ConversationResponse>(
        `/api/conversations/start`,
        {
            chatWithDocument: true,
            conversationId: conversationId,
        },
        signal
    );

    return conversationResponse;
};

export const mediaQueries = {
    DISPLAY: '@media (min-width: 2000px)',
    TABLET: '@media (max-width: 1024px)',
    TABLETPORTRAIT: '@media (max-width: 1280px)',
    LAPTOP: '@media (max-width: 1368px)',
    MOBILE: '@media (max-width: 769px)',
    MOBILEPORTRAIT: '@media (max-width: 526px)',
};

export const componentsMediaQueries = {
    DISPLAY: '(min-width: 2000px)',
    TABLET: '(max-width: 1024px)',
    TABLETPORTRAIT: '(max-width: 1280px)',
    LAPTOP: '(max-width: 1368px)',
    MOBILE: '(max-width: 769px)',
    MOBILEPORTRAIT: '(max-width: 526px)',
};

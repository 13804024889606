/* eslint-disable no-debugger */
import { Box } from '@mui/material';
import { SetStateAction } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { DefaultDragDropZone } from './layout';

type Props = {
    formatsAllowed: string;
    docSize: number;
    isDisabled: boolean;
    isFileDraggedInScreen: boolean;
    isDraggingInsideZone: boolean;
    setIsDraggingInsideZone: (value: SetStateAction<boolean>) => void;
    setIsFileDraggedInScreen: (value: SetStateAction<boolean>) => void;
    handleChangeUpload: (file: File) => Promise<void>;
    handleErrors: (value: 'format' | 'size', docSize?: number) => void;
};

const DocumentUpload = ({
    formatsAllowed,
    docSize,
    isDisabled,
    isFileDraggedInScreen,
    isDraggingInsideZone,
    handleChangeUpload,
    setIsDraggingInsideZone,
    setIsFileDraggedInScreen,
    handleErrors,
}: Props) => {
    const fileFormats = formatsAllowed.split(',');

    return (
        <Box
            position="absolute"
            top={0}
            width="100%"
            height="100%"
            borderRadius={1}
            zIndex={isFileDraggedInScreen ? 1 : 0}
        >
            <FileUploader
                maxSize={docSize}
                types={fileFormats}
                handleChange={handleChangeUpload}
                onDraggingStateChange={(value: boolean | ((prevState: boolean) => boolean)) => {
                    setIsDraggingInsideZone(value);
                }}
                onDrop={() => setIsFileDraggedInScreen(false)}
                onTypeError={() => handleErrors('format')}
                onSizeError={() => handleErrors('size', docSize)}
                hoverTitle=" "
                disabled={isDisabled}
            >
                <DefaultDragDropZone isDragging={isDraggingInsideZone} isDisabled={isDisabled} />
            </FileUploader>
        </Box>
    );
};

export { DocumentUpload };

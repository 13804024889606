import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { DocumentsSettings, LinksSettings, ModelServiceSettings } from '@features/settings';
import { LinearProgress } from '@mui/material';
import { ChatPage } from '@pages/ChatPage';
import { RouteGuard } from './RouteGuard';

type Props = {
    links: LinksSettings;
    modelService: ModelServiceSettings;
    documentSettings: DocumentsSettings;
};

const ApplicationRoutes = ({ links, modelService, documentSettings }: Props) => (
    <Routes>
        <Route
            element={
                <Suspense fallback={<LinearProgress />}>
                    <RouteGuard />
                </Suspense>
            }
        >
            <Route
                index
                element={<ChatPage links={links} modelService={modelService} documentSettings={documentSettings} />}
            />
        </Route>
    </Routes>
);

export { ApplicationRoutes };

import { useEffect, useRef, useState } from 'react';
import { ask } from './api';
import { CHAT_KEY, CHAT_MESSAGES, ChatMessage, DOC_NAME, EMPTY_GUID, setChatInStorage } from './domain';

const loadChat = (messages: ChatMessage[], setMessages: (value: ChatMessage[]) => void) => {
    if (!messages || messages.length === 0) {
        const conversationContentJsonText = sessionStorage.getItem(CHAT_MESSAGES);
        conversationContentJsonText && setMessages(JSON.parse(conversationContentJsonText));
    }
};

const useChat = () => {
    const abortControllerRef = useRef<AbortController | null>(null);
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [sendQuestion, setSendQuestion] = useState<boolean>(false);
    const [waitingForChatId, setWaitingForChatId] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [chatId, setChatId] = useState<string>(sessionStorage.getItem(CHAT_KEY) || EMPTY_GUID);

    useEffect(() => {
        loadChat(messages, setMessages);
        setIsLoading(false);
    }, []);

    useEffect(() => {
        abortControllerRef.current = new AbortController();

        sendQuestion &&
            messages &&
            messages.length > 0 &&
            messages.at(-1)?.role !== 'document' &&
            (async () => {
                const response = await ask(messages[messages.length - 1]!, chatId, abortControllerRef?.current?.signal);
                const messagesResponse = [...messages, response.answer] as ChatMessage[];
                setMessages(messagesResponse);
                setChatInStorage(messagesResponse);
                setChatId(response.conversationId);
                setSendQuestion(false);
            })();

        !sendQuestion && messages && messages.length > 0 && setChatInStorage(messages);

        return () => {
            abortControllerRef?.current?.abort();
        };
    }, [messages, sendQuestion]);

    useEffect(() => {
        if (messages.filter(f => f.role === 'document' || f.role === 'documentError').length === 1) {
            chatId === EMPTY_GUID ? setWaitingForChatId(true) : setWaitingForChatId(false);
        }

        chatId !== EMPTY_GUID && sessionStorage.setItem(CHAT_KEY, chatId);
    }, [chatId, messages]);

    const sendMessage = (value: string) => {
        const messagesWithQuestion = [...messages, { text: value, role: 'user' }] as ChatMessage[];
        setMessages(messagesWithQuestion);
        setChatInStorage(messagesWithQuestion);
        setSendQuestion(true);
    };

    const startNewChat = () => {
        setMessages([]);
        setChatId(EMPTY_GUID);
        sessionStorage.removeItem(CHAT_MESSAGES);
        sessionStorage.removeItem(CHAT_KEY);
        sessionStorage.removeItem(DOC_NAME);
    };

    return {
        messages,
        isSendingQuestion: sendQuestion,
        waitingForChatId,
        isLoading,
        chatId,
        sendMessage,
        startNewChat,
        setChatId,
        setMessages,
    };
};

export { useChat };

import { componentsMediaQueries } from '@assets/mediaQuery/media-query';
import { LinksSettings } from '@features/settings';
import { Box, IconButton, Link, Menu, MenuItem, Typography, useMediaQuery } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
type Props = {
    links: LinksSettings;
};

const ITEM_HEIGHT = 48;
const Footer = ({ links }: Props) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery(componentsMediaQueries.TABLET);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const navigate = (link: string) => {
        window.open(link);
    };
    return !isMobile ? (
        <Box
            padding={'0px'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            position={'relative'}
            sx={{ userSelect: 'none', gap: '5px' }}
        >
            <Typography borderRight={1} borderColor={grey[300]} paddingRight={1} variant="caption">
                {t('chat.footer.text')}
            </Typography>
            <Link borderRight={1} borderColor={grey[300]} paddingRight={1} href={links.privacyPolicy} target="_blank">
                <Typography variant="caption">{t('chat.footer.privacyPolicy')}</Typography>
            </Link>
            <Link href={links.legalWarning} target="_blank">
                <Typography variant="caption">{t('chat.footer.legalNotice')}</Typography>
            </Link>
        </Box>
    ) : (
        <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ userSelect: 'none' }}
            padding={'0px'}
        >
            <Typography lineHeight={1.5} variant="caption">
                {t('chat.footer.textMobile')}
            </Typography>
            <IconButton
                aria-label="more"
                id="long-button"
                color="primary"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <HelpOutlineIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                        },
                    },
                }}
            >
                <MenuItem
                    onClick={() => {
                        navigate(links.privacyPolicy);
                    }}
                >
                    <OpenInNewIcon color="primary" style={{ marginRight: 5 }} />
                    <Typography variant="caption">{t('chat.footer.privacyPolicy')}</Typography>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        navigate(links.legalWarning);
                    }}
                >
                    <OpenInNewIcon color="primary" style={{ marginRight: 5 }} />
                    <Typography variant="caption">{t('chat.footer.legalNotice')}</Typography>
                </MenuItem>
            </Menu>
        </Box>
    );
};

export { Footer };

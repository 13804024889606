import { signoutRedirect } from '@features/auth';
import { CHAT_KEY, CHAT_MESSAGES, DOC_NAME } from '@features/chat/domain';
import { CustomAvatar, UserProfileExtended } from '@features/user';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Box, Button, Divider, Popover, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

const Logout = () => {
    const { t } = useTranslation();
    const [anchorPopover, setAnchorPopover] = useState<HTMLButtonElement | null>(null);
    const id = anchorPopover ? 'simple-popover' : undefined;
    const auth = useAuth();

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorPopover(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorPopover(null);
    };

    const logoutHandler = async () => {
        sessionStorage.removeItem(CHAT_MESSAGES);
        sessionStorage.removeItem(CHAT_KEY);
        sessionStorage.removeItem(DOC_NAME);
        await signoutRedirect(auth);
    };

    const userProfile = auth?.user?.profile as UserProfileExtended;

    return (
        <>
            <Button
                onClick={handleClick}
                aria-describedby={id}
                sx={{ textTransform: 'initial' }}
                color="inherit"
                data-testid="dropdown-btn"
            >
                <Typography variant="body2">{userProfile.name}</Typography>
                <KeyboardArrowDownIcon />
            </Button>
            <Popover
                id={id}
                open={Boolean(anchorPopover)}
                data-testid="popover"
                anchorEl={anchorPopover}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <Box width={350} padding={2} display="flex" justifyContent="space-between" alignItems="center">
                    <CustomAvatar />
                    <div>
                        <Typography
                            align="right"
                            color="primary"
                            fontSize={16}
                            fontWeight={600}
                            variant="subtitle2"
                            noWrap={true}
                            maxWidth={240}
                        >
                            {userProfile.name}
                        </Typography>
                        <Typography align="right" fontSize={16} variant="subtitle2" noWrap={true} maxWidth={240}>
                            {userProfile.jobTitle}
                        </Typography>
                    </div>
                </Box>
                <Divider />
                <Box width={1}>
                    <Button
                        onClick={logoutHandler}
                        sx={{
                            width: 1,
                            textTransform: 'initial',
                            gap: 1,
                            justifyContent: 'start',
                            height: 56,
                            paddingX: 2,
                        }}
                        color="inherit"
                    >
                        <PowerSettingsNewIcon />
                        <Typography variant="body1" fontWeight={'bold'}>
                            {t('general.logout')}
                        </Typography>
                    </Button>
                </Box>
            </Popover>
        </>
    );
};

export { Logout };

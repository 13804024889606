import { agent } from '@shared/api';
import { DownloadBinaryResponse, DownloadResponse } from './domain';

export const getDownloadUri = async (conversationId: string, signal?: AbortSignal) => {
    const response = await agent.get<DownloadResponse>(`/api/files/download?conversationId=${conversationId}`, signal);
    return response;
};

export const getDownloadBinary = async (conversationId: string, signal?: AbortSignal) => {
    const response = await agent.get<DownloadBinaryResponse>(
        `/api/files/downloadbinary?conversationId=${conversationId}`,
        signal
    );
    return response;
};

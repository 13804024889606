import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/system';
import LogoRomeu from '@assets/images/romeuBlue.svg?react';
import { componentsMediaQueries } from '@assets/mediaQuery/media-query';

const NoMessages = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const disclaimerTexts = [
        t('chat.noMessagesDisclaimer1'),
        t('chat.noMessagesDisclaimer2'),
        t('chat.noMessagesDisclaimer3'),
        t('chat.noMessagesDisclaimer4'),
    ];
    const isMobile = useMediaQuery(componentsMediaQueries.TABLET);

    return (
        <Box
            width={'100%'}
            height={'100%'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-between'}
            alignContent={'center'}
        >
            <Box height={'100%'} display={'flex'} flexWrap={'wrap'} alignContent={'center'}>
                <LogoRomeu height={300} width={'100%'} />

                {isMobile && (
                    <Box width={'100%'} marginBottom={1} borderRadius={1} padding={2}>
                        <Typography align="center" variant="body2" fontWeight={500} fontSize={'1.5rem'} color={'black'}>
                            {t('chat.canYouHelp')}
                        </Typography>
                    </Box>
                )}
            </Box>

            {!isMobile && (
                <Box
                    width={'100%'}
                    maxWidth={1024}
                    maxHeight={500}
                    marginX={'auto'}
                    display={'flex'}
                    flexDirection={isMobile ? 'column' : undefined}
                    justifyContent={'space-between'}
                    alignContent={'start'}
                    alignItems={isMobile ? 'center' : undefined}
                    gap={'10px'}
                >
                    {disclaimerTexts.map((disclaimerText, i) => (
                        <Box
                            width={isMobile ? '90%' : '32%'}
                            bgcolor={theme.palette.primary.main}
                            borderRadius={1}
                            padding={2}
                            key={i}
                        >
                            <Typography variant="body2" fontWeight={500} color={'white'}>
                                {disclaimerText}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    );
};

export { NoMessages };

import { UserManager } from 'oidc-client-ts';
import { AuthContextProps } from 'react-oidc-context';

const signoutRedirect = async (auth: AuthContextProps) => {
    await getUserManagerService(auth).signoutRedirect();
};

const signinRedirect = async (auth: AuthContextProps) => {
    await getUserManagerService(auth).signinRedirect();
};

const getUserManagerService = (auth: AuthContextProps) => {
    const userManager = new UserManager(auth.settings);

    return userManager;
};

export { signinRedirect, signoutRedirect };

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
export const CHAT_MESSAGES = 'myChat';
export const CHAT_KEY = 'chatKey';
export const DOC_NAME = 'documentName';

type Role = 'user' | 'assistant' | 'loading' | 'error' | 'document' | 'documentError';

type ChatMessage = {
    text: string;
    role: Role;
};

type ConversationRequest = {
    conversation: {
        message: ChatMessage;
    };
    id: string;
};

type ConversationResponse = {
    answer: ChatMessage;
    conversationId: string;
};

export type { ChatMessage, ConversationRequest, ConversationResponse, Role };

export const setChatInStorage = (value: ChatMessage[]) => {
    sessionStorage.setItem(CHAT_MESSAGES, JSON.stringify(value));
};

import LogoGpt from '@assets/images/logo/logo_gpt_romeu_icono_bl.svg?react';
import { CustomAvatar } from '@features/user';
import { Avatar, Box } from '@mui/material';
import { useTheme } from '@mui/system';
import { Role } from '../domain';

type Props = {
    role: Role;
};

const MessageAvatar = ({ role }: Props) => {
    const theme = useTheme();

    return role === 'user' ? (
        <CustomAvatar width={40} height={40} />
    ) : role === 'assistant' || role === 'error' || role === 'loading' ? (
        <Avatar
            sx={{
                width: 40,
                height: 40,
                bgcolor: theme.palette.secondary.main,
                color: 'white',
            }}
            data-testid="avatar-model"
        >
            <LogoGpt width={35} height={17} />
        </Avatar>
    ) : (
        <Box sx={{ width: 40, height: 40, userSelect: 'none' }} />
    );
};
export { MessageAvatar };

import { DocumentsSettings } from '@features/settings';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { ChangeEvent, useRef } from 'react';
import './uploadButton.scss';
import { acceptedFormats, validateFile } from './domain';

type Props = {
    handleChange: (file: File) => Promise<void>;
    documentSettings: DocumentsSettings;
    isDisabled: boolean;
    handleErrors: (errorType: 'format' | 'size', maxSize?: number) => void;
};

const UploadButton = ({ handleChange, documentSettings, isDisabled, handleErrors }: Props) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.currentTarget.files![0] as unknown as File;
        const result = validateFile(file, handleErrors, documentSettings);

        result && handleChange(e.currentTarget.files![0] as unknown as File);

        if (fileInputRef) {
            fileInputRef.current!.value = '';
        }
    };

    return (
        <label htmlFor="file-upload-selector" className={`uploadButton ${isDisabled ? 'disabled' : ''}`}>
            <input
                type="file"
                id="file-upload-selector"
                onChange={e => onChangeHandler(e)}
                size={documentSettings.size}
                accept={acceptedFormats(documentSettings)}
                hidden
                disabled={isDisabled}
                ref={fileInputRef}
            />
            <AttachFileIcon sx={{ color: 'white' }} />
        </label>
    );
};

export { UploadButton };

import { componentsMediaQueries } from '@assets/mediaQuery/media-query';
import { MessageBox, MessagesList, NewChat, NoMessages, useChat } from '@features/chat';
import { DocumentDownload } from '@features/chat/documentDownload';
import { DocumentRemoval } from '@features/chat/documentRemoval';
import { DocumentBannerConfiguration } from '@features/chat/documentUpload';
import { DOC_NAME } from '@features/chat/domain';
import { Footer } from '@features/chat/footer/Footer';
import { Header } from '@features/header';
import { DocumentsSettings, LinksSettings, ModelServiceSettings } from '@features/settings';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, CircularProgress, Slide, useMediaQuery } from '@mui/material';
import { Banner, BannerConfiguration } from '@shared/ui';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';

type Props = {
    links: LinksSettings;
    modelService: ModelServiceSettings;
    documentSettings: DocumentsSettings;
};

export const bannerInitState: DocumentBannerConfiguration = {
    show: false,
    status: 'notUploading',
};

const ChatPage = ({ links, modelService, documentSettings }: Props) => {
    const [messageBoxFocus, setMessageBoxFocus] = useState<boolean>(false);
    const [disableDocumentUpload, setDisableDocumentUpload] = useState<boolean>(false);
    const [manageBanner, setManageBanner] = useState<DocumentBannerConfiguration>(bannerInitState);
    const [isFileDeleted, setIsFileDeleted] = useState<boolean>(false);
    const containerRef = useRef<HTMLElement>(null);
    const showNewChat = useMediaQuery(componentsMediaQueries.TABLETPORTRAIT);
    const {
        chatId,
        isSendingQuestion,
        isLoading,
        waitingForChatId,
        messages,
        setMessages,
        setChatId,
        sendMessage,
        startNewChat,
    } = useChat();

    const handleStartNewChat = () => {
        startNewChat();
        setMessageBoxFocus(true);
        setDisableDocumentUpload(false);
        setManageBanner(bannerInitState);
    };

    useEffect(() => {
        const fileName = sessionStorage.getItem(DOC_NAME);
        if (fileName !== null && fileName !== '') {
            setManageBanner({ show: true, status: 'finished' });
            setDisableDocumentUpload(true);
        }
    }, []);

    useEffect(() => {
        if (isFileDeleted) {
            setManageBanner({ show: false, status: 'notUploading' });
            sessionStorage.removeItem(DOC_NAME);
            setDisableDocumentUpload(false);
            setIsFileDeleted(false);
        }
    }, [isFileDeleted]);

    const getBannerSettings = () => {
        if (manageBanner.status === 'started') {
            return {
                theme: 'secondary',
                text: t('documents.banner.loading'),
                icon: <CircularProgress size={20} sx={{ color: 'white' }} />,
            } as BannerConfiguration;
        } else if (manageBanner.status === 'startedDownload') {
            return {
                theme: 'secondary',
                text: t('documents.banner.startDownload'),
                icon: <CircularProgress size={20} sx={{ color: 'white' }} />,
            } as BannerConfiguration;
        } else {
            const fileName = sessionStorage.getItem(DOC_NAME);
            return {
                theme: 'primary',
                text: t('documents.banner.download', { documentName: fileName }),
                icon: <DownloadIcon />,
            } as BannerConfiguration;
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                width: '100%',
                overflow: 'hidden',
            }}
        >
            <Box sx={{ padding: 0 }}>
                <Header startNewChat={handleStartNewChat} isDisabled={messages.length === 0} />
            </Box>
            <Box sx={{ padding: 0 }} display={'flex'}>
                {manageBanner.show && (
                    <Slide in={manageBanner.show} container={containerRef.current}>
                        <Box display="flex" justifyContent="start" alignItems="stretch" width="100%" sx={{ zIndex: 2 }}>
                            <DocumentDownload
                                chatId={chatId}
                                enabled={manageBanner.status === 'finished'}
                                setManageBanner={setManageBanner}
                            >
                                <Banner settings={getBannerSettings()} />
                            </DocumentDownload>
                            {manageBanner.status === 'finished' && (
                                <DocumentRemoval
                                    conversationId={chatId}
                                    setIsDeleted={setIsFileDeleted}
                                    fileName={sessionStorage.getItem(DOC_NAME)!}
                                    setMessages={setMessages}
                                />
                            )}
                        </Box>
                    </Slide>
                )}
            </Box>
            <Box
                sx={{
                    flexGrow: 1,
                    overflowY: 'auto',
                    p: 0,
                    mb: 0,
                    display: isLoading ? 'flex' : 'block',
                    justifyContent: isLoading ? 'center' : 'flex-start',
                    alignItems: isLoading ? 'center' : 'flex-start',
                }}
            >
                {isLoading ? (
                    <CircularProgress sx={{ minWidth: 100, minHeight: 100, width: '100%' }} />
                ) : (
                    <>
                        {!isLoading && messages.length ? (
                            <MessagesList
                                messages={messages}
                                isSendingQuestion={isSendingQuestion}
                                isBannerVisible={manageBanner.show}
                            />
                        ) : (
                            <NoMessages />
                        )}
                    </>
                )}
            </Box>

            <Box
                sx={{
                    p: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <MessageBox
                    sendMessage={sendMessage}
                    isSendingQuestion={isSendingQuestion || waitingForChatId}
                    maxTokens={modelService.maxTokens}
                    tokenCounterInfoUrl={modelService.urlTokenCounterInfo}
                    isFocused={messageBoxFocus}
                    setIsFocused={setMessageBoxFocus}
                    documentSettings={documentSettings}
                    chatId={chatId}
                    setChatId={setChatId}
                    setMessages={setMessages}
                    setManageBanner={setManageBanner}
                    disableDocumentUpload={disableDocumentUpload}
                    setDisableDocumentUpload={setDisableDocumentUpload}
                    setDivMessageBoxHeight={() => null}
                />
            </Box>

            <Box
                sx={{
                    p: 1,
                    textAlign: 'center',
                }}
            >
                {showNewChat ? undefined : (
                    <NewChat startNewChat={handleStartNewChat} isDisabled={messages.length === 0} />
                )}
                <Footer links={links} />
            </Box>
        </Box>
    );
};
export { ChatPage };

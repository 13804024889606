/* eslint-disable no-debugger */
import { SetStateAction, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChatMessage, DOC_NAME, EMPTY_GUID } from '../domain';
import { startChatWithDocument, uploadDocument } from './api';
import { DocumentUploadResponse, FileStatus, UploadingStatus, getUploadMessage } from './domain';

type UseDocumentUploadProps = {
    isDraggedInMessageBoxWrapper: boolean;
    setMessages: (value: SetStateAction<ChatMessage[]>) => void;
    setChatId: (value: string) => void;
    setDisableDocumentUpload: (value: boolean) => void;
    chatId: string;
    setErrorMessage: (value: string) => void;
};

export const useDocumentUpload = ({
    isDraggedInMessageBoxWrapper,
    setMessages,
    setChatId,
    chatId,
    setDisableDocumentUpload,
    setErrorMessage,
}: UseDocumentUploadProps) => {
    const [uploadingDocStatus, setUploadingDocStatus] = useState<UploadingStatus>('notUploading');
    const [errorCode, setErrorCode] = useState<string>('');
    const [fileToUpload, setFileToUpload] = useState<File | null>(null);
    const [isFileDraggedInScreen, setIsFileDraggedInScreen] = useState<boolean>(false);
    const [isDraggingInsideZone, setIsDraggingInsideZone] = useState<boolean>(false);
    const abortControllerRef = useRef<AbortController | null>(null);
    const dragCounter = useRef(0);
    const { t } = useTranslation();

    const onSuccessResponse = (responseUpload: DocumentUploadResponse) => {
        sessionStorage.setItem(DOC_NAME, responseUpload.file.fileName);
        setUploadingDocStatus('finished');
        setDisableDocumentUpload(true);
    };

    const onErrorResponse = (errorCode: string) => {
        setErrorCode(errorCode);
        setUploadingDocStatus('error');
        setDisableDocumentUpload(false);
    };

    const handleDragEvent = (event: DragEvent) => {
        event.preventDefault();
        event.stopPropagation();

        switch (event.type) {
            case 'dragenter':
                dragCounter.current++;
                if (event.dataTransfer?.items && event.dataTransfer.items.length > 0) {
                    setIsFileDraggedInScreen(true);
                }
                break;
            case 'dragover':
                break;
            case 'dragleave':
                dragCounter.current--;
                if (dragCounter.current === 0 && !isDraggedInMessageBoxWrapper && !isDraggingInsideZone) {
                    setErrorMessage('');
                    setIsFileDraggedInScreen(false);
                }
                break;
            case 'drop':
                dragCounter.current = 0;
                setIsFileDraggedInScreen(false);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        abortControllerRef.current = new AbortController();
        if (uploadingDocStatus !== 'notUploading') {
            const newMessage = getUploadMessage(uploadingDocStatus, errorCode);
            const messages = (prevMessages: ChatMessage[]) => [...prevMessages, newMessage] as ChatMessage[];
            setMessages(messages);
            setErrorCode('');

            uploadingDocStatus === 'started' &&
                (async () => {
                    const response = await startChatWithDocument(chatId);
                    chatId === EMPTY_GUID && setChatId(response.id);

                    if (fileToUpload !== null) {
                        const responseUpload = await uploadDocument(fileToUpload, response.id);

                        responseUpload.file.status === FileStatus.error
                            ? onErrorResponse(responseUpload.file.errorCode)
                            : onSuccessResponse(responseUpload);
                    }
                })();
        }
        return () => {
            abortControllerRef?.current?.abort();
        };
    }, [uploadingDocStatus]);

    useLayoutEffect(() => {
        const handleDragEvents = (event: DragEvent) => handleDragEvent(event);
        window.addEventListener('dragenter', handleDragEvents);
        window.addEventListener('dragover', handleDragEvents);
        window.addEventListener('dragleave', handleDragEvents);
        window.addEventListener('drop', handleDragEvents);

        return () => {
            window.removeEventListener('dragenter', handleDragEvents);
            window.removeEventListener('dragover', handleDragEvents);
            window.removeEventListener('dragleave', handleDragEvents);
            window.removeEventListener('drop', handleDragEvents);
        };
    }, [isDraggingInsideZone, isDraggedInMessageBoxWrapper]);

    const handleChangeUpload = async (file: File) => {
        setDisableDocumentUpload(true);
        setUploadingDocStatus('started');
        file !== null && setFileToUpload(file);
        setErrorMessage('');
    };

    const handleFileErrors = (errorType: 'format' | 'size', maxSize?: number) => {
        switch (errorType) {
            case 'format':
                setErrorMessage(t('documents.errors.format'));
                break;
            case 'size':
                setErrorMessage(t('documents.errors.size', { fileMaxSize: maxSize }));
                break;
        }

        setIsFileDraggedInScreen(false);
    };

    return {
        isFileDraggedInScreen,
        isDraggingInsideZone,
        setIsDraggingInsideZone,
        setIsFileDraggedInScreen,
        handleChangeUpload,
        uploadingDocStatus,
        handleFileErrors,
    };
};

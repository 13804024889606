import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { CustomDialog, ConfirmationButtons } from '@shared/ui';

type Props = {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    acceptAction: () => void;
};

const NewChatDialog = ({ isOpen, setIsOpen, acceptAction }: Props) => {
    const { t } = useTranslation();

    return (
        <CustomDialog
            isOpen={isOpen}
            title={t('chat.newChat.label')}
            cancelHandler={() => setIsOpen(false)}
            footer={
                <ConfirmationButtons
                    acceptHandler={() => {
                        setIsOpen(false);
                        acceptAction();
                    }}
                    cancelHandler={() => setIsOpen(false)}
                />
            }
        >
            <Typography variant="body1">{t('chat.newChat.dialogContent')}</Typography>
        </CustomDialog>
    );
};

export { NewChatDialog };

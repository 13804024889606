import { useTranslation } from 'react-i18next';
import { AppBar, Toolbar, Typography, useMediaQuery } from '@mui/material';
import LogoRomeu from '@assets/images/romeuWhite.svg?react';
import { Logout } from '../logout';
import { NewChat } from '@features/chat';
import { componentsMediaQueries } from '@assets/mediaQuery/media-query';
type Props = {
    startNewChat: () => void;
    isDisabled: boolean;
};
const Header = ({ startNewChat, isDisabled }: Props) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery(componentsMediaQueries.TABLETPORTRAIT);

    return (
        <AppBar color="secondary" position="relative" sx={{ height: 64, top: 0, userSelect: 'none' }}>
            <Toolbar sx={{ gap: 3 }}>
                <LogoRomeu height={30} title={t('appName')} />
                <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                    {t('appSurname')}
                </Typography>

                {!isMobile ? null : <NewChat startNewChat={startNewChat} isDisabled={isDisabled} />}

                <Logout />
            </Toolbar>
        </AppBar>
    );
};

export { Header };
